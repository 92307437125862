.home {
  color: #fff;
  text-align: center;
  background: rgba(0, 0, 0, .15) url('./../../assets/HeroImage-Mobile.JPG');
  background-blend-mode: darken;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.home .hashtag {
  padding-top: 20px;
  font-size: 22px;
  color: #fff;
}

.home .text-section {
  margin-top: 20px;
}

.home h1 {
  font-size: 42px;
  letter-spacing: 2px;
}

.home h1 span {
  font-size: 26px;
  color: #ffdd59;
}

.home .para-txt {
  margin-top: 8px;
}

.home p {
  font-size: 26px;
  letter-spacing: 1px;
}

@media only screen and (max-width: 340px) {
  .home .hashtag {
    font-size: 20px;
  }

  .home h1 { 
    font-size: 34px;
  }

  .home h1 span {
    font-size: 26px;
  }

  .home p { 
    font-size: 24px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 980px) and (orientation:landscape) {
  .home {
    height: 220vh;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1005px) {
  .home .hashtag {
    font-size: 34px;
  }

  .home h1 { 
    font-size: 62px;
  }

  .home h1 span {
    font-size: 40px;
  }

  .home p { 
    font-size: 38px;
  }
}

@media only screen and (min-width: 1006px) {
  .home .text-section {
    margin-top: 0px;
  }

  .home .hashtag {
    padding-top: 10px;
    font-size: 38px;
  }

  .home h1 { 
    font-size: 62px;
  }

  .home .para-txt {
    margin-top: 0px;
  }

  .home h1 span {
    font-size: 40px;
  }

  .home p { 
    font-size: 36px;
  }
}

@media only screen and (min-width: 1280px) {
  .home {
    background: rgba(0, 0, 0, .15) url('./../../assets/HeroImage.JPG');
    background-blend-mode: darken;
    height: 100vh;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
}