.intro-section {
  height: 100vh;
  background-image: linear-gradient(to top, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%);
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.intro-section .groom {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.intro-section .groom p {
  font-family: 'Allura', sans-serif;
  font-size: 40px;
  text-shadow: 2px 2px #fff;
  color: #043a4a;
}

.intro-section .groom img {
  width: 50%;
  border: 2px solid #043a4a;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  transform: rotate(-10deg);
}

.intro-section .bride {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.intro-section .bride p {
  font-family: 'Allura', sans-serif;
  font-size: 44px;
  text-shadow: 2px 2px #fff;
  color: #043a4a;
}

.intro-section .bride img {
  width: 50%;
  border: 2px solid #043a4a;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.9);
  transform: rotate(10deg);
}

@media only screen and (min-width: 321px) and (max-width: 980px) and (orientation:landscape) {
  .intro-section {
    height: 220vh;
  }

  .intro-section .groom img {
    width: 35%;
  }

  .intro-section .bride img {
    width: 35%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1005px) and (orientation:portrait) {
  .intro-section .groom img {
    width: 40%;
  }

  .intro-section .groom p {
    font-size: 60px;
  }

  .intro-section .bride img {
    width: 40%;
  }

  .intro-section .bride p {
    font-size: 60px;
  }
}

@media only screen and (min-width: 1006px) {
  .intro-section {
    padding: 100px 0px;
    height: 100%;
  }

  .intro-section .groom img {
    width: 25%;
  }

  .intro-section .groom p {
    font-size: 75px;
  }

  .intro-section .bride img {
    width: 25%;
  }

  .intro-section .bride p {
    font-size: 75px;
  }
}

@media only screen and (min-width: 1280px) {
  .intro-section .groom img {
    width: 20%;
  }

  .intro-section .bride img {
    width: 20%;
  }
}