.mapouter {
  min-height: 100vh;
  background: #eee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 30px;
  padding-top: 20px;
}

.mapouter iframe {
  border: 2px solid #04364A;
  margin: 0 auto;
  display: block;
}

.mapouter .header {
  font-size: 36px;
  color: #04364A;
  text-align: center;
  position: relative;
  margin-bottom: 30px;
  margin-top: 30px;
}

.mapouter .header:before {
  content: "";
  display: block;
  width: 50px;
  height: 2px;
  background: #04364A;
  left: 20%;
  top: 50%;
  position: absolute;
}

.mapouter .header:after {
  content: "";
  display: block;
  width: 50px;
  height: 2px;
  background: #04364A;
  right: 20%;
  top: 50%;
  position: absolute;
}

.mapouter .events {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 15px;
  justify-content: center;
  align-items: center;
}

.mapouter .events .event { 
  width: 90%;
  background-color: #fff;
  padding: 20px;
  text-align: center;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.mapouter .events .event img {
  width: 90px;
}

.mapouter .events .event p {
  font-size: 14px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  color: #04364A;
  line-height: 2;
} 

.mapouter .events .event .title {
  font-size: 38px;
  font-family: 'Allura', sans-serif;
} 

.atcb-button-wrapper {
  font-size: 10px !important;
}

@media only screen and (max-width: 340px) {
  .mapouter .header {
    margin-top: 30px;
  }

  .mapouter .events .event .title {
    font-size: 36px;
  } 

  .mapouter .header:before {
    left: 10%;
  }

  .mapouter .header:after {
    right: 10%;
  }

  .mapouter .events .event p {
    font-size: 14px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 980px) and (orientation:landscape) {
  .mapouter {
    min-height: 220vh;
  }

  .mapouter .events {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1005px) {
  .mapouter .header {
    font-size: 48px;
  }

  .mapouter .header:before {
    width: 80px;
    height: 4px;
    left: 25%;
  }

  .mapouter .header:after {
    width: 80px;
    height: 4px;
    right: 25%;
  }

  .mapouter .events {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mapouter iframe {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1006px) {
  .mapouter {
    padding-top: 40px;
  }

  .mapouter .header {
    font-size: 48px;
  }

  .mapouter .header:before {
    width: 80px;
    height: 4px;
    left: 25%;
  }

  .mapouter .header:after {
    width: 80px;
    height: 4px;
    right: 25%;
  }

  .mapouter .events {
    margin-bottom: 30px;
    margin-top: 20px;
  }

  .mapouter .events .event {
    width: 35%;
  }

  .mapouter iframe {
    margin-top: 20px;
    width: 70%;
  }
}

@media only screen and (min-width: 1280px) {
  .mapouter .header:before {
    width: 80px;
    height: 4px;
    left: 35%;
  }

  .mapouter .header:after {
    width: 80px;
    height: 4px;
    right: 35%;
  }
}